import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/img/register/back.png'
import _imports_1 from '@/assets/img/newHome/langWhite.png'
import _imports_2 from '../../assets/img/register/email.png'
import _imports_3 from '../../assets/img/register/code.png'
import _imports_4 from '../../assets/img/register/lock.png'
import _imports_5 from '../../assets/img/register/refer.png'


const _hoisted_1 = { class: "register_page" }
const _hoisted_2 = { class: "register" }
const _hoisted_3 = { class: "top" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown_lang"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "ctx" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "tip" }
const _hoisted_9 = { class: "input-box row-center" }
const _hoisted_10 = { class: "input-box row-center" }
const _hoisted_11 = { class: "input-box row-center" }
const _hoisted_12 = { class: "input-box row-center" }
const _hoisted_13 = { class: "input-box row-center" }
const _hoisted_14 = ["loading"]
const _hoisted_15 = ["loading"]
const _hoisted_16 = { class: "policy" }

import {
  fetchCode,
  fetchLanguageList,
  fetchLogin,
  fetchRegister,
} from '@/api/fetcher';
import langUrl from '@/assets/img/newHome/lang.png';
import { lang } from '@/lang';
import PopularizeTop from '@/components/NewTop/index.vue';
import { ElForm, ElFormItem, ElInput } from 'element-plus';
import type { FormInstance, FormRules } from 'element-plus';
import { Loading } from 'vant';
import { reactive, ref, onMounted, onUnmounted, computed } from 'vue';
import CountDown from '@/components/CountDown';
import dataCenter from '@/dataCenter';
import { useRouter } from 'vue-router';
import { LanguageListResp, LoginByEmailReq } from '@/api/protocol';
import message from '@/utils/message';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();
const isLoginOrRegister = ref(false);
const isPasswordOrCaptcha = ref(false);
const formRef = ref<FormInstance>();
/** lang选择 */
const isOpenLang = ref(false);
/** 遮罩层控制 */
const isOpenMask = ref(false);

const rules: FormRules = {
  email: [
    {
      required: true,
      pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
      trigger: 'blur',
      message: lang.v.emailRuleError,
    },
  ],
  code: [{ required: true, trigger: 'blur' }],
  password: [
    {
      required: true,
      trigger: 'blur',
      message: lang.v.enterPassword,
    },
  ],
  secondPassword: [
    {
      required: true,
      trigger: 'blur',
      message: lang.v.enterSecondPassword,
    },
    //第二处输入密码与第一次相同
    {
      validator: (rule, value) => {
        if (!value) return true;
        return value === form.password;
      },
      trigger: 'blur',
      message: lang.v.confirmPassword,
    },
  ],
};
/** 语言列表 */
const LanguageList = dataCenter.language;
/** 获取语言函数 */
const getLanguageList = () => {
  fetchLanguageList({ status: 1 }).then(res => {
    if (res) {
      LanguageList.save(res);
    }
  });
};
/** 开启语言选择 */
const openLang = (type?: number) => {
  if (type == 0) {
    isOpenLang.value = false;
    isOpenMask.value = false;
    return;
  }
  isOpenLang.value = !isOpenLang.value;
  isOpenMask.value = !isOpenMask.value;
};
/** 选中语言函数 */
const onSelectLang = (item: LanguageListResp) => {
  lang.change(item.abbreviation);
  isOpenLang.value = false;
  isOpenMask.value = false;
};
const form = reactive({
  email: '',
  code: '',
  refer: '',
  password: '',
  secondPassword: '',
});
const getcodeText = computed(() => {
  return lang.v.getCode;
});
const codeInfo = reactive({ text: '', loading: false, count: 0 });
const submiting = ref(false);

const countDown = new CountDown();
//设置登录注册函数
const onShowRegister = (showLogin: boolean) => {
  formRef.value?.resetFields();
  isLoginOrRegister.value = showLogin;
  if (!showLogin) {
    isPasswordOrCaptcha.value = showLogin;
  }
};
//设置密码登录验证码登录函数
const onShowCaptcha = (showCaptcha: boolean) => {
  formRef.value?.resetFields();
  isPasswordOrCaptcha.value = showCaptcha;
};
onMounted(async () => {
  form.refer = dataCenter.webConfig.query.ref;
  if (!LanguageList.data?.length) {
    getLanguageList();
  }
});
onUnmounted(() => {
  countDown.clear();
});
async function onGetCode() {
  if (codeInfo.count) {
    return;
  }
  await formRef.value?.validateField('email');
  if (codeInfo.loading) {
    return;
  }
  codeInfo.loading = true;
  fetchCode(form.email)
    .then(resp => {
      if (!resp) {
        return;
      }
      countDown.start(90, value => {
        codeInfo.count = value;
        if (value) {
          codeInfo.text = `${String(value)}S`;
        } else {
          codeInfo.text = '';
        }
      });
    })
    .finally(() => {
      codeInfo.loading = false;
    });
}
async function onSubmitRegister() {
  if (submiting.value) {
    return;
  }
  await formRef.value?.validate();
  fetchRegister({
    email: form.email,
    clientType: 'PC',
    captcha: form.code,
    invitationCode: form.refer,
    password: form.password,
    secondPassword: form.secondPassword,
  })
    .then(resp => {
      if (resp) {
        doLogin({
          email: form.email,
          clientType: 'PC',
          password: form.password,
        });
        message.success(lang.v.registerSuccess);
        router.push('/');
      }
    })
    .finally(() => {
      submiting.value = false;
    });
}
async function onSubmitLogin() {
  if (submiting.value) {
    return;
  }
  const submitLoginList: LoginByEmailReq = {
    email: form.email,
    clientType: 'PC',
    invitationCode: form.refer,
    captcha: undefined,
    password: undefined,
  };
  await formRef.value?.validateField('email');
  if (isPasswordOrCaptcha.value) {
    await formRef.value?.validateField('code');
    submitLoginList.captcha = form.code;
  } else {
    await formRef.value?.validateField('password');
    submitLoginList.password = form.password;
  }
  doLogin(submitLoginList);
}
const doLogin = (loginList: LoginByEmailReq) => {
  submiting.value = true;
  fetchLogin(loginList)
    .then(resp => {
      if (resp) {
        dataCenter.user.data = resp.userInfo;
        // window.location.href = window.downloadUrl;
        router.push('/');
      }
    })
    .finally(() => {
      submiting.value = false;
    });
};

function onBack() {
  if (window.history.length < 2) {
    router.push('/');
  } else {
    router.back();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "back",
          onClick: onBack
        }),
        _createElementVNode("img", {
          src: _imports_1,
          class: "lang_img",
          alt: "",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (openLang()), ["stop"]))
        }),
        (isOpenLang.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LanguageList).data, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(item?.abbreviation === _unref(lang).m ? 'active' : ''),
                  key: item.id,
                  onClick: ($event: any) => (onSelectLang(item))
                }, _toDisplayString(item.name), 11, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      (isOpenMask.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "mask",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (openLang(0)))
          }))
        : _createCommentVNode("", true),
      _cache[17] || (_cache[17] = _createElementVNode("div", { style: {"height":"0.8rem"} }, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(lang).v.register), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(lang).v.regisgerAndEnjoy), 1),
        _createVNode(_unref(ElForm), {
          ref_key: "formRef",
          ref: formRef,
          style: {"width":"100%"},
          model: form,
          rules: rules
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ElFormItem), {
              prop: "email",
              class: "mb0 mt20"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                    src: _imports_2,
                    class: "icon"
                  }, null, -1)),
                  _createVNode(_unref(ElInput), {
                    class: "field",
                    modelValue: form.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.email) = $event)),
                    placeholder: _unref(lang).v.enterEmail,
                    maxlength: "120"
                  }, null, 8, ["modelValue", "placeholder"])
                ])
              ]),
              _: 1
            }),
            _withDirectives(_createVNode(_unref(ElFormItem), {
              prop: "code",
              class: "mb0 mt20"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _cache[12] || (_cache[12] = _createElementVNode("img", {
                    src: _imports_3,
                    class: "icon"
                  }, null, -1)),
                  _createVNode(_unref(ElInput), {
                    class: "field",
                    modelValue: form.code,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.code) = $event)),
                    placeholder: _unref(lang).v.enterCode,
                    maxlength: "10"
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createElementVNode("span", {
                    class: "coder row-center",
                    onClick: onGetCode
                  }, [
                    _withDirectives(_createVNode(_unref(Loading), { size: "small" }, null, 512), [
                      [_vShow, codeInfo.loading]
                    ]),
                    _withDirectives(_createElementVNode("span", null, _toDisplayString(codeInfo.text||getcodeText.value), 513), [
                      [_vShow, !codeInfo.loading]
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 512), [
              [_vShow, !isLoginOrRegister.value || isPasswordOrCaptcha.value]
            ]),
            _withDirectives(_createVNode(_unref(ElFormItem), {
              prop: "password",
              class: "mb0 mt20"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _cache[13] || (_cache[13] = _createElementVNode("img", {
                    src: _imports_4,
                    class: "icon"
                  }, null, -1)),
                  _createVNode(_unref(ElInput), {
                    class: "field",
                    modelValue: form.password,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.password) = $event)),
                    placeholder: _unref(lang).v.enterPassword,
                    maxlength: "120"
                  }, null, 8, ["modelValue", "placeholder"])
                ])
              ]),
              _: 1
            }, 512), [
              [_vShow, !isPasswordOrCaptcha.value]
            ]),
            _withDirectives(_createElementVNode("div", {
              class: "mt20 register",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (onShowCaptcha(!isPasswordOrCaptcha.value)))
            }, [
              _withDirectives(_createElementVNode("span", null, _toDisplayString(_unref(lang).v.LoginCode), 513), [
                [_vShow, !isPasswordOrCaptcha.value]
              ]),
              _withDirectives(_createElementVNode("span", null, _toDisplayString(_unref(lang).v.LoginPassword), 513), [
                [_vShow, isPasswordOrCaptcha.value]
              ])
            ], 512), [
              [_vShow, isLoginOrRegister.value]
            ]),
            _withDirectives(_createVNode(_unref(ElFormItem), {
              prop: "secondPassword",
              class: "mb0 mt20"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _cache[14] || (_cache[14] = _createElementVNode("img", {
                    src: _imports_4,
                    class: "icon"
                  }, null, -1)),
                  _createVNode(_unref(ElInput), {
                    class: "field",
                    modelValue: form.secondPassword,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.secondPassword) = $event)),
                    placeholder: _unref(lang).v.enterSecondPassword,
                    maxlength: "120"
                  }, null, 8, ["modelValue", "placeholder"])
                ])
              ]),
              _: 1
            }, 512), [
              [_vShow, !isLoginOrRegister.value]
            ]),
            _withDirectives(_createVNode(_unref(ElFormItem), {
              prop: "refer",
              class: "mb0 mt20"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _cache[15] || (_cache[15] = _createElementVNode("img", {
                    src: _imports_5,
                    class: "icon"
                  }, null, -1)),
                  _createVNode(_unref(ElInput), {
                    class: "field",
                    modelValue: form.refer,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((form.refer) = $event)),
                    placeholder: _unref(lang).v.InputInvite,
                    disabled: _unref(dataCenter).webConfig.query.ref
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ])
              ]),
              _: 1
            }, 512), [
              [_vShow, !isLoginOrRegister.value]
            ]),
            _withDirectives(_createElementVNode("div", {
              class: "mt20 submit center",
              loading: submiting.value,
              onClick: onSubmitLogin
            }, _toDisplayString(_unref(lang).v.signIn), 9, _hoisted_14), [
              [_vShow, isLoginOrRegister.value]
            ]),
            _withDirectives(_createElementVNode("div", {
              class: "mt20 submit center",
              loading: submiting.value,
              onClick: onSubmitRegister
            }, _toDisplayString(_unref(lang).v.goRegister), 9, _hoisted_15), [
              [_vShow, !isLoginOrRegister.value]
            ])
          ]),
          _: 1
        }, 8, ["model"]),
        _withDirectives(_createElementVNode("div", {
          class: "mt16 register",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (onShowRegister(false)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_unref(lang).v.goRegister), 1)
        ], 512), [
          [_vShow, isLoginOrRegister.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createTextVNode(_toDisplayString(_unref(lang).v.signPropt) + " ", 1),
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            class: "imp",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(router).push('/article?type=policy')))
          }, _toDisplayString(_unref(lang).v.policy), 1),
          _cache[16] || (_cache[16] = _createTextVNode(" & ")),
          _createElementVNode("span", {
            class: "imp",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(router).push('/article?type=agreement')))
          }, _toDisplayString(_unref(lang).v.agreement), 1)
        ])
      ])
    ])
  ]))
}
}

})